import React from "react"
import Layout from "../components/Layout"
import Services from "../components/Services"
import ContactForm from "../components/ContactForm";
import SEO from "../components/SEO";
import DevWidget from '../assets/PictureWidgetDev.jpg';
import MktWidget from '../assets/PictureWidgetMkt.jpg';
import DsnWidget from '../assets/PictureWidgetDsn.jpg';
import { StaticImage } from "gatsby-plugin-image";
import Typewriter from 'typewriter-effect';
// ...GatsbyImageSharpFluid

const Rentals = () => {
  return (
    <><SEO title="Services" />
    <Layout>
    <section>
      <div className="hero-containerSubPage">
        <div className="heroTextOverlay">
          <center>
            <h1 className="heroText">View Our Services</h1>
            <Typewriter
            options={{
              strings: ['Custom Website Design', 'Mobile App Development', 'Marketing', 'Logo Design', 'Web App Development'],
              autoStart: true,
              loop: true,
              wrapperClassName: 'cursor'
            }}
          />
          </center>
        </div>
        <StaticImage
          alt="Our Services"
          className="backgroundImage"
          style={{position: "absolute"}}
          src="../assets/ServiceHero.jpeg"
        />
      </div>
    </section>
      <Services />
      <section className="section-padBot section-padTop">
        <div className='container section-padTopx2 '>
          <div className='row'>
            <div className='col-md-6 containerParent'>
              <h3 className="section-padTop textDirSwap">Web Design</h3>
              <p className="section-padTop textDirSwap">We understand the difficulties that come when creating a successful website and know how to solve them. Websites are critical to any buisness's marketing platform let us help you make this an easy process. We can integrate your existing systems (third-party or internal) or help you decide which new ones you could use.</p>
            </div>
            <div className='col-md-6 '>
              <img src={DevWidget} class="img-responsive" alt="Web and Development" />
            </div>
          </div>

          <div className='container section-padTopx3'>
            <div className='row'>
              <div className='col-md-6'>
                <img src={MktWidget} class="img-responsive" alt="Web and Development" />
              </div>
              <div className='col-md-6 text-left'>
                <h3 className="section-padTop textDirSwap">Marketing</h3>
                <p className="section-padTop textDirSwap">The first part of marketing any product is to have a website. Afterwards it is best to dig into the data. This is where we can come in. Each website comes with google analytics for you to dig into your traffic data. We automate this process to send you monthly excel files with easy to read information.</p>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6 text-left containerParent'>
              <h3 className="section-padTopx3 textDirSwap">Design Services</h3>
              <p className="section-padTop textDirSwap">Every company needs a logo of some sort. Dont stress about learning a design tool such as photoshop, just reach out to us! We will work with you to create a logo to be proud of. Ontop of our logo services we also offer video and image editing.</p>
            </div>
            <div className='col-md-6'>
              <img src={DsnWidget} class="img-responsive" alt="Web and Development" />
            </div>
          </div>

        </div>
      </section>
      <ContactForm />
    </Layout></>
  )
}


export default Rentals
